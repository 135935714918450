<template>
  <div class="c-report-line-item" :class="{ indent: indent, 'not-bold': notBold, header }">
    <!-- Label -->
    <div class="label">
      {{ label }}
    </div>
    <!-- Value -->
    <div class="value">
      <template v-if="value !== '' && value !== null && value >= 0">
        <!-- Values -->
        <template v-if="!units">
          {{ value }}
        </template>
        <template v-else-if="value >= 0 && ['area', 'percent'].includes(units)">
          {{ new Intl.NumberFormat('de-CH').format(value) }}
        </template>
        <template v-else-if="value >= 0 && !['area', 'percent'].includes(units)">
          {{ new Intl.NumberFormat('de-CH').format(value) }}
        </template>
        <template v-else>-</template>
        <!-- Units -->
        <template v-if="units === 'area'">m²</template>
        <template v-else-if="units === 'percent'">%</template>
        <template v-if="value >= 0">
          <template v-if="units === 'emissions'">t CO₂e</template>
          <template v-else-if="units === 'emissionsPerArea'">kg CO₂e/m²</template>
          <template v-else-if="units === 'energy'">MWh</template>
          <template v-else-if="units === 'energyPerArea'">kWh/m²</template>
        </template>
      </template>
      <!-- Fallback -->
      <template v-else>-</template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
    },
    header: {
      type: Boolean,
      default: false,
    },
    units: {
      type: String, // 'area', 'percent',  'emissions', 'emissionsPerArea', 'energy', or 'energyPerArea'
    },
    notBold: {
      type: Boolean,
      default: false,
    },
    indent: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-report-line-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  width: 100%;
  outline: 0px solid transparent;
  transition: outline 0.1s;

  &:hover {
    background-color: #f5f5f5;
    outline: 2px solid #f5f5f5;
  }
  &.header {
    font-weight: 700;

    &:hover {
      background: none;
      outline: none;
    }
  }

  &.indent {
    margin-left: 16px;
    width: calc(100% - 16px);
  }

  &.not-bold {
    font-weight: normal;
  }

  & > div {
    width: 50%;
  }

  & .value {
    flex: 1;
    text-align: right;
  }

  & .label {
    flex: 2;
  }
}
</style>
