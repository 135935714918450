<i18n>
    {
      "de": {
        "buildingsCount": "Anzahl Liegenschaften",
        "noExistingBuildings": "Keine Bestandesliegenschaften",
        "xUnit": "Liegenschaften",
        "xUnitEbf": "m²",
        "groupByKeys": {
          "dataquality": "Datenqualität"
        }
      }
    }
    </i18n>

<template>
  <div class="c-coverage-report-chart">
    <div v-if="chartData.length === 0" class="no-data">
      <p>
        {{ $t('noExistingBuildings') }}
      </p>
    </div>
    <PieChart
      v-else
      :chart-data="processedChartData"
      :options="options"
      :grouping-name="$t(`groupByKeys.dataquality`)"
    />
  </div>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import PieChart from '@/components/shared/charts/PieChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    PieChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    groupedValues() {
      return Object.values(
        this.chartData.reduce(function (res, value) {
          const tag = value.dataQuality
          if (tag === undefined) {
            return res
          }
          const keyVal = tag
          if (!res[keyVal]) {
            res[keyVal] = { key: keyVal, result: 0 }
          }
          res[keyVal].result += value['area']
          return res
        }, {})
      )
    },

    labels() {
      return this.groupedValues && this.groupedValues.map((g) => this.$t(`_dataQualityReidaReport.${g.key}`))
    },

    colors() {
      return this.groupedValues && this.groupedValues.map((g) => colorPalettes.dataQualityReidaReportColors[g.key])
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 100,
            right: 100,
          },
        },
        scales: {
          xAxis: {
            display: false,
          },
          yAxis: {
            display: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
          },
          tooltip: {
            callbacks: {
              label: (item) => this.getTooltipLabel(item, { unit: this.$t('xUnitEbf') }),
            },
          },
        },
      }
    },

    processedChartData() {
      return {
        datasets: [
          {
            label: '',
            data: this.groupedValues && this.groupedValues.map((g) => g.result),
            hoverOffset: 4,
            backgroundColor: this.colors,
          },
        ],
        labels: this.labels,
      }
    },
  },
}
</script>

<style lang="scss">
.c-coverage-report-chart {
  position: relative;
  width: 410px;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  & canvas {
    min-width: 350px;
  }

  & .chart-container {
    position: relative;
    height: 410px;

    @media screen and (max-width: 1000px) {
      height: 100%;
    }
  }

  & .no-data {
    position: relative;
    height: 260px;

    & p {
      width: 100%;
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.5;
    }
  }
}
</style>
