<!--
Required: portfolio_id, yearActive
Provides: reports, reportBuildings
-->
<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      reportLoading: (state) => state.reports.loading,
      reportLoaded: (state) => state.reports.loaded,
      reportBuildingsLoading: (state) => state.reports.buildingsLoading,
      reportBuildingsLoaded: (state) => state.reports.buildingsLoaded,
      reportError: (state) => state.reports.error,
    }),

    ...mapGetters({
      __reports: 'reports/getReports',
      __buildings: 'reports/getBuildings',
    }),

    reports() {
      return this.__reports()
    },

    reportBuildings() {
      return this.__buildings()
    },
  },

  watch: {
    portfolioId() {
      this.__reset()
      this.__getReportByPortfolioIdAndYear({
        portfolioId: this.portfolio_id,
        year: this.yearActive,
      })
      this.__getReportBuildingsByPortfolioIdAndYear({
        portfolioId: this.portfolio_id,
        year: this.yearActive,
      })
    },

    yearActive() {
      if (this.yearActive === -1) return
      // If the year changes, only get new data if the report is not already loaded
      const report = this.reports.find((r) => r.reportYear === this.yearActive)
      if (!report) {
        this.__getReportByPortfolioIdAndYear({
          portfolioId: this.portfolio_id,
          year: this.yearActive,
        })
        this.__getReportBuildingsByPortfolioIdAndYear({
          portfolioId: this.portfolio_id,
          year: this.yearActive,
        })
      }
    },
  },

  created() {
    this.__reset()
    this.__getReportByPortfolioIdAndYear({
      portfolioId: this.portfolio_id,
      year: this.yearActive,
    })
    this.__getReportBuildingsByPortfolioIdAndYear({
      portfolioId: this.portfolio_id,
      year: this.yearActive,
    })
  },

  methods: {
    ...mapActions({
      __getReportByPortfolioIdAndYear: 'reports/getReportByPortfolioIdAndYear',
      __getReportBuildingsByPortfolioIdAndYear: 'reports/getReportBuildingsByPortfolioIdAndYear',
      __reset: 'reports/reset',
    }),
  },
}
</script>
