<i18n>
  {
    "de": {
      "property": "Liegenschaft",
      "benchmarkTitle": "REIDA Benchmark {year}"
    }
  }
  </i18n>

<template>
  <div class="c-bubble-report-legend">
    <div v-for="item in legendItems" :key="item.label" class="item">
      <div :style="{ backgroundColor: item.color }" class="point" />
      <div class="label">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    benchmark: {
      type: Object,
      required: true,
    },
    palette: {
      type: Object,
      required: true,
    },
  },

  computed: {
    legendItems() {
      const items = [
        {
          label: this.$t('property'),
          color: this.palette.data.background,
        },
      ]
      if (this.benchmark) {
        items.push({
          label: this.$t('benchmarkTitle', { year: this.benchmark.description }),
          color: this.palette.benchmark.point.background,
        })
      }
      return items
    },
  },
}
</script>

<style lang="scss" scoped>
.c-bubble-report-legend {
  display: flex;
  justify-content: center;
  gap: 16px;

  & .item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    & .point {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 5px;
    }

    & .label {
      font-size: 14px;
      margin-right: 10px;
    }
  }
}
</style>
