<template>
  <div class="c-emissions-report-chart">
    <EmissionsReportChartWrapper
      key="emissionsreport"
      :chart-data="conformChartData"
      :benchmark="benchmark"
      @click="onBubbleClick"
    />
  </div>
</template>

<script>
import EmissionsReportChartWrapper from '@/components/report/EmissionsReportChartWrapper.vue'

export default {
  components: {
    EmissionsReportChartWrapper,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    benchmark: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    conformChartData() {
      return this.chartData.filter((b) => b.dataQuality === 'CONFORM')
    },
  },

  methods: {
    onBubbleClick(building) {
      if (building)
        this.$router.push({
          name: 'buildingDetails',
          params: { portfolio_id: this.portfolio.id, building_id: building.building_id },
        })
    },
  },
}
</script>

<style lang="scss">
.c-emissions-report-chart {
  width: 100%;
  max-width: 460px;
  padding: 0 32px 0 0;

  /* https://github.com/jtblin/angular-chart.js/issues/614#issuecomment-509649618 */
  & canvas {
    width: 100% !important;
    min-width: 350px;
  }

  & .bubble-chart {
    height: 250px;
  }
}
</style>
