<i18n>
{
  "de": {
    "pageTitle": "CO₂mpass Report",
    "pageTitleHtml": "CO<sub>2</sub>mpass Report",
    "createdOn": "Erstellt am",
    "createNewReportButton": "Neu erstellen",
    "createReportButton": "Report erstellen",
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "subtitlePortfolioOverview": "Portfolio Überblick",
    "kpisOverview": "AMAS/REIDA-Kennzahlen",
    "subTitleProperties": "Liegenschaften",
    "kpisProperties": "Kennzahlen Liegenschaften",
    "subTitleEmissions": "Emissionen",
    "kpisEmissions": "Kennzahlen CO₂e Emissionen",
    "subTitleEnergy": "Energie",
    "kpisEnergy": "Kennzahlen Energie",
    "numberOfProperties": "Anzahl Liegenschaften",
    "numberOfPropertiesPortfolio": "Anzahl Liegenschaften Portfolio",
    "numberOfUnusedPropertiesPortfolio": "Anzahl nicht anwendbare Liegenschaften",
    "numberOfExisitingProperties": "Anzahl Bestandesliegenschaften",
    "numberOfExistingAndConformProperties": "Anzahl massgebende Liegenschaften",
    "numberOfExistingPropertiesWithEnergyData": "Bestand mit genügend Energiedaten (Massgebend)",
    "numberOfExistingPropertiesWithOutEnergyData": "Bestand ohne genügend Energiedaten",
    "numberOfNonExistingProperties": "Nicht-Bestand",
    "totalArea": "Gesamte Fläche Bestandesliegenschaften",
    "totalAreaOfExisitingAndConformProperties": "Massgebende Fläche",
    "coverage": "Abdeckungsgrad",
    "co2Emissions": "Emissionen (Scope 1-2)",
    "co2EmissionsPerArea": "Emissionsintensität",
    "energyConsumption": "Energieverbrauch (Endenergie)",
    "energyConsumptionPerArea": "Energieintensität",
    "shareFossil": "Anteil fossile Energie",
    "shareRenewable": "Anteil erneuerbare Energie",
    "charts": {
      "scatterplot": "Streudiagramm",
      "dataQuality": "Datenqualität",
      "co2EmissionsPerArea": "CO₂e Emissionsintensität",
      "energyConsumptionPerArea": "Energieintensität",
      "conform": "Genügend",
      "nonConform": "Ungenügend",
      "co2Emissions": "Emissionen (kg CO₂e/m²)",
      "energyConsumption": "Energieverbrauch (kWh/m²)"
    },
    "noData": "Keine Reports gefunden",
    "loading": "Report wird geladen...",
    "errorMessage": "Fehler: Der Bericht konnte nicht erstellt werden. Bitte überprüfen Sie die Liegenschaften und Energieträger. Um einen REIDA-Bericht zu erstellen, muss allen Energieträgern ein REIDA-Äquivalent zugewiesen werden. Bitte beachten Sie, dass bei der Zuordnung von REIDA-Äquivalenten alle Liegenschaften neu berechnet werden.",
    "errorMessageYearInFuture": "Hinweis: Das Startjahr ihres Portfolios liegt in der Zukunft ({year}). Bitte beachten Sie, dass CO₂mpass Reports nur für das aktuelle Jahr und frühere Jahre erstellt werden können.",
    "resetError": "Schliessen",
    "methodology": "Methodik",
    "methodologyInformation": {
      "title": "Informationen zum CO₂mpass-Report nach AMAS/REIDA",
      "p": "Das Reporting zeigt die umweltrelevanten Kennzahlen, die von der Asset Management Association (AMAS) erarbeitet wurden und mit der Methodik von REIDA kongruent sind.",
      "points": {
        "1": "Die abgebildeten CO₂e-Emissionen entsprechen Scope 1 und Scope 2 gemäss GHG-Protokoll.",
        "2": "Die CO₂e-Emissionen werden in Übereinstimmung mit dem GHG-Protokoll nach dem location-based Ansatz rapportiert.",
        "3": "Die Klimakorrektur erfolgt nach der ATD-Methode gemäss SIA 380:2022.",
        "4": "Auf eine Leerstandskorrektur der Energieverbräuche wird verzichtet.",
        "5": "Transaktionsliegenschaften werden nicht berücksichtigt (Aktivstatus)",
        "6": "Voraussetzung für die Erstellung des Berichts ist lediglich das Vorhandensein von Wärmeverbrauchsdaten."
      }
    },
    "methodologyCategories": {
      "title": "Definition der Liegenschaftskategorisierung",
      "part1": {
        "p": "Die Kategorisierung im Report nach REIDA sind nachfolgend erklärt. Dafür werden zuerst die Kriterien aufgelistet:",
        "1": "Die Liegenschaft hat einen Standort inkl. GIS Koordinaten.",
        "2": "Die Liegenschaft hat einen Wärmebedarf (Raumwärme und Warmwasser) grösser als 0.",
        "3": "Die Liegenschaft ist aktiv.",
        "4": "Die Fläche der Liegenschaft ist komplett über VMF oder EBF definiert.",
        "5": "Die Liegenschaft hat für jede definierte Heizung Messdaten, die mindestens 3 Monate im Reportingjahr liegen."
      },
      "part2": {
        "p": "Diese Kriterien werden jeweils im Reportingjahr ausgewertet. Die Kategorien kann man dann so definieren:",
        "1": "Bestandesliegenschaft (genügend Messdaten) erfüllen alle A bis E.",
        "2": "Bestandesliegenschaft (fehlende Messdaten) erfüllen alle A bis D aber nicht E.",
        "3": "Nicht-Bestandesliegenschaften erfüllen A und B aber nicht C, D oder E.",
        "4": "Nicht andwendbar erfüllt A oder B nicht."
      }
    }
  }
}
</i18n>

<template>
  <!-- /!\ Important: CO₂mpass/Reida Reporting Page will only be shown if the simulation start year is less than the current year -->
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="p-report-page">
    <!-- Page title -->
    <template #header>
      <PageTitle :title="$t('pageTitleHtml')" :portfolio="portfolio" />
    </template>

    <template #default>
      <!-- Controls Container -->
      <template v-if="isSimStartYearLessThanCurrentYear">
        <div class="controls">
          <!-- Left corner: Years -->
          <div class="controls__years">
            <div class="years">
              <div class="year">
                <button type="button" :class="{ active: yearActive === -1 }" @click="onYearClicked(-1)">
                  {{ $t('methodology') }}
                </button>
              </div>
              <div v-for="(year, index) in allYears" :key="index" class="year">
                <button type="button" :class="{ active: year === yearActive }" @click="onYearClicked(year)">
                  {{ year }}
                </button>
              </div>
            </div>
          </div>

          <!-- Right corner: Buttons -->
          <div v-if="yearActive !== -1" class="controls__buttons">
            <!-- Date created on -->
            <div v-if="reportActive">
              {{ $t('createdOn') }}: {{ new Date(reportActive.createdAt).toLocaleString('de-CH') }}
            </div>

            <!-- Create new report -->
            <button
              v-if="getPortfolioPermission('EDIT_REPORTS')"
              type="button"
              class="button"
              :disabled="reportLoading"
              @click="onCreateNewReport(yearActive)"
            >
              {{ $t('createNewReportButton') }}
            </button>
          </div>
        </div>

        <hr />
      </template>

      <template v-if="reportLoading || reportBuildingsLoading">
        <div class="loading">
          <ClipLoader size="18px" color="#000" />
          {{ $t('loading') }}
        </div>
      </template>
      <template v-else-if="reportError">
        <div class="error">
          {{ $t('errorMessage') }}
        </div>
        <button type="button" class="button" @click="__resetError">
          {{ $t('resetError') }}
        </button>
      </template>

      <template
        v-if="
          reportActive &&
          !reportError &&
          !reportLoading &&
          !reportBuildingsLoading &&
          yearActive !== -1 &&
          isSimStartYearLessThanCurrentYear
        "
      >
        <!-- Report -->
        <div class="report">
          <!-- Report Overview Summary Section -->
          <div class="report-section">
            <div class="kpis">
              <div class="header">
                <img src="/icons/reports/report.svg" alt="Report" />
                <h2>{{ $t('subtitlePortfolioOverview') }}</h2>
              </div>
              <div class="table">
                <ReportLineItem :label="$t('kpisOverview')" :value="reportActive.reportYear" header />
                <hr />
                <ReportLineItem
                  :label="$t('numberOfExistingAndConformProperties')"
                  :value="reportActive.existingAndConformProperties"
                />
                <ReportLineItem
                  :label="$t('totalAreaOfExisitingAndConformProperties')"
                  :value="reportActive.existingAndConformPropertiesArea"
                  units="area"
                />
                <ReportLineItem :label="$t('coverage')" :value="coverage" units="percent" />
                <ReportLineItem :label="$t('energyConsumption')" :value="energyConsumption" units="energy" />
                <ReportLineItem
                  :label="$t('energyConsumptionPerArea')"
                  :value="energyConsumptionPerArea"
                  units="energyPerArea"
                />
                <ReportLineItem :label="$t('co2Emissions')" :value="co2Emissions" units="emissions" />
                <ReportLineItem
                  :label="$t('co2EmissionsPerArea')"
                  :value="co2EmissionsPerArea"
                  units="emissionsPerArea"
                />
                <ReportLineItem :label="$t('shareFossil')" :value="shareFossil" units="percent" />
              </div>
            </div>

            <!-- Bubble chart -->
            <div class="chart">
              <h3>{{ $t('charts.scatterplot') }}</h3>
              <BubbleReportChart
                :chart-data="reportBuildingsActive"
                :portfolio="portfolio"
                :benchmark="reidaBenchmark"
              />
            </div>
          </div>

          <hr />

          <!-- Properties Section -->
          <div class="report-section">
            <div class="kpis">
              <div class="header">
                <img src="/icons/reports/properties.svg" alt="Properties" />
                <h2>{{ $t('subTitleProperties') }}</h2>
              </div>
              <div class="table">
                <ReportLineItem :label="$t('kpisProperties')" :value="reportActive.reportYear" header />
                <hr />
                <ReportLineItem
                  :label="$t('numberOfPropertiesPortfolio')"
                  :value="reportActive.portfolioProperties"
                  not-bold
                />
                <ReportLineItem
                  :label="$t('numberOfUnusedPropertiesPortfolio')"
                  :value="reportActive.portfolioUnusedProperties"
                  not-bold
                />
                <ReportLineItem
                  :label="$t('numberOfProperties')"
                  :value="reportActive.portfolioProperties - reportActive.portfolioUnusedProperties"
                />
                <ReportLineItem
                  :label="$t('numberOfExistingPropertiesWithEnergyData')"
                  :value="reportActive.existingAndConformProperties"
                  indent
                  not-bold
                />
                <ReportLineItem
                  :label="$t('numberOfExistingPropertiesWithOutEnergyData')"
                  :value="reportActive.existingProperties - reportActive.existingAndConformProperties"
                  indent
                  not-bold
                />
                <ReportLineItem
                  :label="$t('numberOfNonExistingProperties')"
                  :value="
                    reportActive.portfolioProperties -
                    reportActive.existingProperties -
                    reportActive.portfolioUnusedProperties
                  "
                  indent
                  not-bold
                />
                <ReportLineItem
                  :label="$t('totalArea')"
                  :value="reportActive.existingPropertiesArea"
                  not-bold
                  units="area"
                />
                <ReportLineItem
                  :label="$t('totalAreaOfExisitingAndConformProperties')"
                  :value="reportActive.existingAndConformPropertiesArea"
                  units="area"
                />
                <ReportLineItem :label="$t('coverage')" :value="coverage" units="percent" />
              </div>
            </div>

            <!-- Pie chart -->
            <div class="chart">
              <h3>{{ $t('charts.dataQuality') }}</h3>
              <CoverageReportChart :chart-data="reportBuildingsActive" :portfolio="portfolio" />
            </div>
          </div>

          <hr />

          <!-- Energy Section -->
          <div class="report-section">
            <div class="kpis">
              <div class="header">
                <img src="/icons/reports/energy.svg" alt="Energy" />
                <h2>{{ $t('subTitleEnergy') }}</h2>
              </div>
              <div class="table">
                <ReportLineItem :label="$t('kpisEnergy')" :value="reportActive.reportYear" header />
                <hr />
                <ReportLineItem
                  :label="$t('totalArea')"
                  :value="reportActive.existingPropertiesArea"
                  not-bold
                  units="area"
                />
                <ReportLineItem
                  :label="$t('totalAreaOfExisitingAndConformProperties')"
                  :value="reportActive.existingAndConformPropertiesArea"
                  units="area"
                />
                <ReportLineItem :label="$t('coverage')" :value="coverage" units="percent" />
                <ReportLineItem :label="$t('energyConsumption')" :value="energyConsumption" units="energy" />
                <ReportLineItem
                  :label="$t('energyConsumptionPerArea')"
                  :value="energyConsumptionPerArea"
                  units="energyPerArea"
                />
                <ReportLineItem :label="$t('shareFossil')" :value="shareFossil" units="percent" />
                <ReportLineItem :label="$t('shareRenewable')" :value="shareRenewable" units="percent" />
              </div>
            </div>

            <!-- Chart -->
            <div class="chart">
              <h3>{{ $t('charts.energyConsumptionPerArea') }}</h3>
              <EnergyReportChart
                :chart-data="reportBuildingsActive"
                :portfolio="portfolio"
                :benchmark="reidaBenchmark"
              />
            </div>
          </div>

          <hr />

          <!-- Emissions Section -->
          <div class="report-section">
            <div class="kpis">
              <div class="header">
                <img src="/icons/reports/emissions.svg" alt="Emissions" />
                <h2>{{ $t('subTitleEmissions') }}</h2>
              </div>
              <div class="table">
                <ReportLineItem :label="$t('kpisEmissions')" :value="reportActive.reportYear" header />
                <hr />
                <ReportLineItem
                  :label="$t('totalArea')"
                  :value="reportActive.existingPropertiesArea"
                  not-bold
                  units="area"
                />
                <ReportLineItem
                  :label="$t('totalAreaOfExisitingAndConformProperties')"
                  :value="reportActive.existingAndConformPropertiesArea"
                  units="area"
                />
                <ReportLineItem :label="$t('coverage')" :value="coverage" units="percent" />
                <ReportLineItem :label="$t('co2Emissions')" :value="co2Emissions" units="emissions" />
                <ReportLineItem
                  :label="$t('co2EmissionsPerArea')"
                  :value="co2EmissionsPerArea"
                  units="emissionsPerArea"
                />
              </div>
            </div>

            <!-- Chart -->
            <div class="chart">
              <h3>{{ $t('charts.co2EmissionsPerArea') }}</h3>
              <EmissionsReportChart
                :chart-data="reportBuildingsActive"
                :portfolio="portfolio"
                :benchmark="reidaBenchmark"
              />
            </div>
          </div>
        </div>
      </template>

      <!-- Fallback: No reports generated for year -->
      <template
        v-else-if="
          !reportActive && !reportLoading && !reportError && yearActive !== -1 && isSimStartYearLessThanCurrentYear
        "
      >
        <div class="fallback">
          <div class="warning">
            <img src="/icons/reports/no-reports.png" width="48px" height="48px" alt="No reports" />
            <div class="message">
              {{ $t('noData') }}
            </div>
          </div>

          <ButtonWrapper v-if="getPortfolioPermission('EDIT_REPORTS')">
            <!-- Create new report -->
            <button type="button" class="button" :disabled="reportLoading" @click="onCreateNewReport(yearActive)">
              {{ $t('createReportButton') }}
            </button>
          </ButtonWrapper>
        </div>
      </template>

      <!-- Fallback: No reports possible because sim start year is in the future -->
      <template v-else-if="!isSimStartYearLessThanCurrentYear">
        <div>
          {{ $t('errorMessageYearInFuture', { year: portfolio.sim_start_year }) }}
        </div>
      </template>

      <!-- Methodology -->
      <template v-else-if="yearActive === -1 && isSimStartYearLessThanCurrentYear">
        <div class="report">
          <div class="report-section">
            <div class="methodology">
              <h2>
                {{ $t('methodologyInformation.title') }}
              </h2>
              <p>{{ $t('methodologyInformation.p') }}</p>
              <ul>
                <li v-for="(point, index) in $t('methodologyInformation.points')" :key="index">
                  {{ point }}
                </li>
              </ul>
            </div>
            <div class="methodology">
              <h2>
                {{ $t('methodologyCategories.title') }}
              </h2>
              <p>{{ $t('methodologyCategories.part1.p') }}</p>
              <ul class="letters">
                <li>{{ $t('methodologyCategories.part1.1') }}</li>
                <li>{{ $t('methodologyCategories.part1.2') }}</li>
                <li>{{ $t('methodologyCategories.part1.3') }}</li>
                <li>{{ $t('methodologyCategories.part1.4') }}</li>
                <li>{{ $t('methodologyCategories.part1.5') }}</li>
              </ul>
              <p>{{ $t('methodologyCategories.part2.p') }}</p>
              <ul class="numbers">
                <li>{{ $t('methodologyCategories.part2.1') }}</li>
                <li>{{ $t('methodologyCategories.part2.2') }}</li>
                <li>{{ $t('methodologyCategories.part2.3') }}</li>
                <li>{{ $t('methodologyCategories.part2.4') }}</li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import ReportingReidaReportMixin from '@/pages/vuex-mixins/ReportingReidaReportMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import PageTitle from '@/components/shared/PageTitle.vue'
import ReportLineItem from '@/components/shared/ReportLineItem.vue'
import BubbleReportChart from '@/components/report/BubbleReportChart.vue'
import CoverageReportChart from '@/components/report/CoverageReportChart.vue'
import EmissionsReportChart from '@/components/report/EmissionsReportChart.vue'
import EnergyReportChart from '@/components/report/EnergyReportChart.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

import REIDABenchmark2022 from '@/data/REIDA_benchmark_2022.json'

export default {
  name: 'reida',

  mixins: [
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady, energyCarrierProfiles
    ReportingReidaReportMixin, // Provides: reports, reportBuildings
  ],

  components: {
    ButtonWrapper,
    MainLayout,
    PageTitle,
    ReportLineItem,
    BubbleReportChart,
    CoverageReportChart,
    EmissionsReportChart,
    EnergyReportChart,
    ClipLoader,
  },

  data() {
    return {
      // Years
      yearActive: new Date().getFullYear() - 1,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    reportActive() {
      return this.reports.find((r) => r.reportYear === this.yearActive)
    },

    reportBuildingsActive() {
      return this.reportBuildings.filter((b) => b.reportYear === this.yearActive)
    },

    coverage() {
      return (
        (this.reportActive.existingAndConformPropertiesArea / this.reportActive.existingPropertiesArea) *
        100
      ).toFixed(1)
    },

    co2Emissions() {
      return this.reportActive.s12e ? (this.reportActive.s12e / 1000).toFixed(1) : null
    },

    co2EmissionsPerArea() {
      return (this.reportActive.s12e / this.reportActive.existingAndConformPropertiesArea).toFixed(1)
    },

    energyConsumption() {
      return this.reportActive.energyConsumption ? (this.reportActive.energyConsumption / 1000).toFixed(1) : null
    },

    energyConsumptionPerArea() {
      return (this.reportActive.energyConsumption / this.reportActive.existingAndConformPropertiesArea).toFixed(1)
    },

    shareFossil() {
      return this.reportActive.shareFossil && this.reportActive.energyConsumption
        ? ((this.reportActive.shareFossil / this.reportActive.energyConsumption) * 100).toFixed(1)
        : null
    },

    shareRenewable() {
      return this.reportActive.shareRenewable && this.reportActive.energyConsumption
        ? ((this.reportActive.shareRenewable / this.reportActive.energyConsumption) * 100).toFixed(1)
        : null
    },

    allYears() {
      if (this.isSimStartYearLessThanCurrentYear) {
        const start = this.portfolio.sim_start_year
        const end = Math.min(this.portfolio.sim_end_year, new Date().getFullYear())
        return [...Array(end - start).keys()].map((v) => v + start)
      }
      return []
    },

    isSimStartYearLessThanCurrentYear() {
      return this.portfolio.sim_start_year < new Date().getFullYear()
    },

    reidaBenchmark() {
      const reidaBenchmarks = {
        2023: REIDABenchmark2022,
        2022: REIDABenchmark2022,
        2021: REIDABenchmark2022,
        2020: REIDABenchmark2022,
      }
      return reidaBenchmarks[this.yearActive]
    },
  },

  methods: {
    ...mapActions({
      __createReport: 'reports/createReport',
      __resetError: 'reports/resetError',
    }),

    //
    onYearClicked(year) {
      this.yearActive = year
    },

    //
    onCreateNewReport(year) {
      this.__createReport({
        portfolioId: this.portfolio_id,
        year: year,
      })
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.p-report-page {
  & .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & .controls__years,
    & .controls__buttons {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
    }

    & .controls__buttons {
      justify-content: flex-end;
      align-items: center;
    }
  }

  & .years {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & .year {
      & button {
        background: none;
        border: none;
        font-size: var(--font-s);

        &:hover {
          cursor: pointer;
        }

        &.active {
          color: rgba(0, 0, 0, 1);
          border-bottom: 2px solid #000;
        }

        margin: var(--spacing-xs) var(--spacing-s) var(--spacing-xs) 0;
      }
    }
  }

  & hr {
    margin: var(--spacing-m) 0 0;
    border: none;
    height: 1px;
    background-color: #eee;
  }

  & .loading {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-s);
    margin: var(--spacing-xxl) 0;
  }

  & .error {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-xs);
    margin: var(--spacing-xxl) 0 var(--spacing-m);

    & + .button {
      margin-left: 0;
    }
  }

  /* Report sections */
  & .report {
    display: flex;
    flex-direction: column;

    & hr {
      margin: 0;
    }

    & .report-section {
      display: flex;
      flex-direction: row;

      & > * {
        flex: 1;
        min-height: 480px;
      }

      & .kpis {
        min-width: 350px;
        padding: 32px 40px 32px 0;
        border-right: 1px solid #eee;

        @media screen and (max-width: 1120px) {
          padding: 32px 16px 32px 0;
        }

        & > .header {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: var(--spacing-m);
          margin-bottom: var(--spacing-l);

          & img {
            height: 24px;
            width: 24px;
            opacity: 0.5;
          }

          & h2 {
            font-size: 24px;
            line-height: 24px;
            font-weight: 700;
            margin: 0;
          }
        }

        & .table {
          margin: var(--spacing-s) 0 0 var(--spacing-xxl);
          max-width: 800px;

          @media screen and (max-width: 1120px) {
            margin: var(--spacing-s) 0 0 0;
          }

          & hr {
            border: none;
            height: 1px;
            background-color: #000;
            margin: 8px 0;
          }
        }
      }

      & .chart {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 32px 32px 80px 32px;

        @media screen and (max-width: 1120px) {
          padding: 32px 0px 32px 16px;
        }
      }

      & .methodology {
        flex: 1 1 0;
        padding: 32px 32px 32px 48px;

        @media screen and (max-width: 1120px) {
          padding: 32px 0px 32px 16px;
        }

        & h2 {
          margin: 0 0 var(--spacing-l) 0;
        }

        & ul {
          max-width: 640px;
          padding: 0;
          margin: 0 0 var(--spacing-xl) var(--spacing-l);
          list-style-type: disc;

          &.numbers {
            list-style-type: decimal;
          }

          &.letters {
            list-style-type: upper-alpha;
          }

          & li {
            font-weight: 500;
            margin: 0 0 var(--spacing-s) 0;
          }
        }

        & p {
          max-width: 640px;
          font-weight: 500;
          margin: 0 0 var(--spacing-m) 0;
        }
      }

      & .methodology:first-child {
        border-right: 1px solid #eee;
        padding: 32px 48px 32px 32px;

        @media screen and (max-width: 1120px) {
          padding: 32px 16px 32px 0px;
        }
      }
    }
  }

  /* Fallback: no reports */
  & .fallback {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-m);
    margin: var(--spacing-xxl) 0;

    & .warning {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-xs);

      & img {
        margin: var(--spacing-m) 0;
      }

      & .message {
        font-size: var(--font-l);
        line-height: var(--font-l);
        font-weight: 700;
        color: #bbb;
      }
    }
  }
}
</style>
